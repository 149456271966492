import { Select } from 'antd'
import { IconPlus } from '_svg'
import { useParams } from 'react-router-dom'
import Layout from 'components/layout/Layout'
import Button from 'components/buttons/Button'
import Sidebar from 'components/_landings/Sidebar'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { landingsActions, modalActions, userActions, accountActions, templatesActions } from '_actions'
import HeaderWithTabs from 'components/_landings/HeaderWithTabs'
import CollectionListItem from 'components/_collections/CollectionListItem'
import { accountEmpty, userEmpty } from '_services/Utils'
import StatusRadio from 'components/buttons/StatusRadio'
import SidebarItem from 'components/_landings/SidebarItem'

function LandingSettings() {
  const dispatch = useDispatch()
  const { Option } = Select
  const urlParams = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [hasSidebarConfig, setHasSidebarConfig] = useState(false)
  const [template, setTemplate] = useState({})

  const [landing, setLanding] = useState({
    title: null,
    subtitle: null,
    titleWall: null,
    banner: null,
    slug: null,
    ctaLink: null,
    limit: 10,
    status: '0',
    collections: [],
    sort_by: 'random',
    sidebarTitle: null,
    sidebarItems: [],
    template: null
  })

  const [landingForHeader, setLandingForHeader] = useState({
    name: null,
  })

  const account = useSelector((state) => state.account)
  const user = useSelector((state) => state.authentication.user)
  const templatesRdux = useSelector((state) => state.templates)

  useEffect(() => {
    if (accountEmpty(account)) dispatch(accountActions.getSelf())
    if (userEmpty(user)) dispatch(userActions.getSelf(`/landing-pages/${urlParams.id}/settings`))
  }, [user, account, dispatch, urlParams])

  const landingsRdux = useSelector((state) => state.landings.items)
  // to update landing collection
  const landingCreationCollection = useSelector((state) => state.landings.create.collection)

  useEffect(() => {
    if (landingsRdux.length <= 0) {
      loadLandings()
    } else {
      const myLanding = landingsRdux.find((landing) => landing.id === urlParams.id)
      setLanding({ ...landing, ...myLanding })
      setLandingForHeader(myLanding)
      setIsLoading(false)
    }
  }, [landingsRdux])

  useEffect(() => {
    dispatch(templatesActions.getAllTemplates({
      q: '',
      page: 1,
      limit: 20,
      sort_by: 'last_update',
      sort_order: 'DESC',
    }))
  }, [])

  useEffect(() => {
    if (templatesRdux.items && templatesRdux.items.length > 0 && !isLoading && landing.template) {
      getTemplateInfos(landing.template)
    }
  }, [templatesRdux, isLoading])

  useEffect(() => {
    if (landing.id) setLanding({ ...landing, collections: [landingCreationCollection] })
  }, [landingCreationCollection])

  const loadLandings = () => {
    if (isLoading) return
    setIsLoading(true)
    dispatch(landingsActions.getAllLandings())
  }

  const submitForm = (e) => {
    e.preventDefault()
    console.log(landing)
    setIsLoading(true)
    // destructuring to keep only wanted parameters
    const { created_at, hash, last_updated, assets, ...formData } = landing
    // convert array of objects to array of object's id
    formData.collections = formData.collections.map((c) => c.id)
    dispatch(landingsActions.updateLanding(formData))
  }

  const changeStatus = (value) => {
    setLanding((landing) => {
      return { ...landing, ...{ status: value } }
    })
  }

  const deleteLanding = (e) => {
    dispatch(modalActions.deleteLanding(landing))
  }

  const chooseCollection = (e) => {
    dispatch(modalActions.chooseCollection("landing"))
  }

  const getCollection = () => {
    if (landing.id) return <CollectionListItem data={landing.collections[0]} />
  }

  const getTemplateInfos = (value) => {
    const myTemplate = templatesRdux.items.find((el) => el.id === value)
    setTemplate(myTemplate)

    setHasSidebarConfig(myTemplate.type === 'sidebar')
  }

  const addSidebarItem = () => {
    const temp = {}
    temp.sidebarItems = landing.sidebarItems

    temp.sidebarItems.push({
      'title': null,
      'desc': null,
      'stars': null,
      'tag': null,
      'link': null,
      'pic': null
    })

    setLanding({ ...landing, ...temp })
  }

  const getBase64 = (e) => {
    const temp = {}
    if (e.currentTarget.files.length > 0) {
      const reader = new FileReader()
      reader.readAsDataURL(e.currentTarget.files[0])

      reader.onload = () => {
        temp.banner = reader.result
        setLanding({ ...landing, ...temp })
      }

      reader.onerror = (error) => {
        console.log('Error: ', error)
        return error
      }
    } else {
      temp.banner = null
      setLanding({ ...landing, ...temp })
    }
  }

  return (
    <Layout className="page page-landing-settings">
      <HeaderWithTabs landing={landingForHeader} />
      <div className="with-sidebar">
        <div className="page__content">
          <div className="page__intro">Modifiez les réglages de votre landing et enregistrez vos changements.</div>
          <div className="fluid-grid">
            <form action="" onSubmit={submitForm}>
              <div className="col-12 md-col-8">
                <p className="text-24">Général</p>

                <label htmlFor="title" className="label">
                  Titre de votre landing
                </label>
                <input
                  type="text"
                  name="title"
                  id="title"
                  defaultValue={landing.title}
                  className="text-input"
                  placeholder="Titre*"
                  required
                  onChange={(e) => {
                    const temp = {}
                    temp.title = e.currentTarget.value
                    setLanding({ ...landing, ...temp })
                  }}
                />
                <label htmlFor="slug" className="label">
                  Slug de votre landing (pour l'url)
                </label>
                <input
                  type="text"
                  name="slug"
                  id="slug"
                  defaultValue={landing.slug}
                  className="text-input"
                  placeholder="Slug*"
                  required
                  onChange={(e) => {
                    const temp = {}
                    temp.slug = e.currentTarget.value
                    setLanding({ ...landing, ...temp })
                  }}
                />

                <label htmlFor="status" className="label">
                  Statut du feed
                </label>
                <div className="flex">
                  <StatusRadio
                    value="1"
                    label="Actif"
                    onChangeStatus={changeStatus}
                    isDefaultChecked={landing.status === '1'}
                  />
                  <StatusRadio
                    value="0"
                    label="Brouillon"
                    onChangeStatus={changeStatus}
                    isDefaultChecked={landing.status === '0'}
                  />
                </div>

                <label htmlFor="template" className="label">
                  Template
                </label>
                <Select
                  name="template"
                  value={landing.template}
                  onChange={(value) => {
                    const temp = {}
                    temp.template = value
                    temp.sidebarTitle = null
                    temp.sidebarItems = []
                    setLanding({ ...landing, ...temp })

                    getTemplateInfos(value)
                  }}
                >
                  {templatesRdux && templatesRdux.items.map((t, key) => <Option key={key} value={t.id}>{t.name}</Option>)}
                </Select>

                <label htmlFor="banner" className="label">
                  Bannière
                </label>
                <input
                  type="file"
                  name="banner"
                  id="banner"
                  className="file-input"
                  placeholder="Bannière de la page*"
                  onChange={getBase64}
                />

                <label htmlFor="titleWall" className="label">
                  Titre du mur
                </label>
                <input
                  type="text"
                  name="titleWall"
                  id="titleWall"
                  required
                  className="text-input"
                  placeholder="Titre du mur"
                  defaultValue={landing.titleWall}
                  onChange={(e) => {
                    const temp = {}
                    temp.titleWall = e.currentTarget.value
                    setLanding({ ...landing, ...temp })
                  }}
                />
                <label htmlFor="subtitle" className="label">
                  Sous-titre de votre landing
                </label>
                <input
                  type="text"
                  name="subtitle"
                  id="subtitle"
                  defaultValue={landing.subtitle}
                  className="text-input"
                  placeholder="Sous-titre"
                  onInput={(e) => {
                    const temp = {}
                    temp.subtitle = e.currentTarget.value
                    setLanding({ ...landing, ...temp })
                  }}
                />
                <label htmlFor="ctaLink" className="label">
                  Lien du bouton
                </label>
                <input
                  type="text"
                  name="ctaLink"
                  id="ctaLink"
                  defaultValue={landing.ctaLink}
                  className="text-input"
                  placeholder="Lien*"
                  required
                  onChange={(e) => {
                    const temp = {}
                    temp.ctaLink = e.currentTarget.value
                    setLanding({ ...landing, ...temp })
                  }}
                />

                <label htmlFor="limit" className="label">
                  Nombre de medias maximum
                </label>
                <Select
                  name="limit"
                  value={landing.limit}
                  onChange={(value) => {
                    const temp = {}
                    temp.limit = value
                    setLanding({ ...landing, ...temp })
                  }}
                >
                  <Option value="10">10</Option>
                  <Option value="20">20</Option>
                  <Option value="50">50</Option>
                </Select>


                { hasSidebarConfig && 
                  <>
                    <p className="text-24 mt-10">Configuration sidebar</p>
                    <label htmlFor="sidebarTitle" className="label">
                      Titre de la sidebar
                    </label>
                    <input
                      type="text"
                      name="sidebarTitle"
                      id="sidebarTitle"
                      defaultValue={landing.sidebarTitle}
                      className="text-input"
                      onChange={(e) => {
                        const temp = {}
                        temp.sidebarTitle = e.currentTarget.value
                        setLanding({ ...landing, ...temp })
                      }}
                    />
                    
                    <p className='label'>Eléments de la sidebar</p>
                    
                    {landing.sidebarItems.length > 0 &&
                      <ul className='collection-wrap'>
                        {landing.sidebarItems.map((item, i) => {
                          return (
                            <SidebarItem key={i} item={item} i={i} setLanding={setLanding} landing={landing} open={item.title === null ? true : false} /> 
                          )
                        })}
                      </ul>
                    }

                    <button type="button" className='button-more' onClick={() => addSidebarItem() }><span><IconPlus className="icon" /></span>Ajouter un élément</button>
                  </>
                }

                <p className="text-24 mt-10">Source de la landing page</p>

                <label htmlFor="sorting" className="label">
                  Trier les medias par
                </label>
                <Select
                  name="sort_by"
                  value={landing.sort_by}
                  onChange={(value) => {
                    const temp = {}
                    temp.sort_by = value
                    setLanding({ ...landing, ...temp })
                  }}
                >
                  <Option value="random">Ordre aléatoire</Option>
                  <Option value="created_at">Date de création</Option>
                  {/* <Option value="timestamp">Date du post</Option> */}
                  <Option value="commentsCount">Nombre de commentaires</Option>
                  <Option value="likeCount">Nombre de likes</Option>
                </Select>
                <label htmlFor="locale" className="label">
                  Remontée
                </label>
                <Select name="source" defaultValue="by_collection">
                  <Option value="by_collection">Par collection</Option>
                </Select>
                <div className="selection-wrapper mt-6">
                  <div className="selection-wrapper-content">{getCollection()}</div>
                  <div className="selection-cta mt-6" onClick={chooseCollection}>
                    <div className="picto">
                      <IconPlus className="icon" />
                    </div>
                    {landing.collections.length > 0 ? 'changer de collection' : 'Lier à une collection'}
                  </div>
                </div>
              </div>
              <div className="col-12 md-col-8">&nbsp;</div>
              <div className="col-12 md-col-8 flex justify-start items-start">
                <Button btnStyle="border" color="grey" addedclass="mr-4" onClickFn={deleteLanding}>
                  {'Supprimer la landing page'}
                </Button>
                <Button
                  type="submit"
                  btnStyle="plain"
                  loading={isLoading}
                  disabled={isLoading || landing.collections.length <= 0}
                >
                  Modifier la landing page
                </Button>
              </div>
            </form>
          </div>
        </div>
        <div className="sidebar">
          <div className={`sidebar__content${isLoading ? ' is-loading' : ''}`}>
            {landing && <Sidebar data={landing} template={template} />}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default LandingSettings
